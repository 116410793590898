.toggle-topsearch {
  cursor: pointer;
}

.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 10px !important;
  position: absolute;
  top: 100%;
  margin-top: -20px;
  left: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-left: 220px;
}
@media (max-width: 1170px) {
  .wh-autocomplete-values {
    margin-left: 220px;
  }
}
html.mobilelayout .wh-autocomplete-values {
  margin-left: 0;
  left: 0;
  right: 0;
  margin-top: 0;
}
.wh-autocomplete-values > li {
  padding: 3px 15px;
  line-height: 28px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.15s;
  outline: none;
  color: #3e3e36;
  margin-bottom: 0 !important;
}
.wh-autocomplete-values > li::before {
  display: none !important;
}
.wh-autocomplete-values > li.noresults {
  font-weight: normal;
  font-style: italic;
  color: #999;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  background-color: #f1f1f1;
}
.wh-autocomplete-values > li .match {
  font-weight: bold;
}

.topsearch {
  background-color: #5B4041;
  transition: height 0.3s;
  overflow: hidden;
  height: 0;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 78px;
  left: 0;
  right: 0;
}
html.show-topsearch .topsearch, html.searchpage .topsearch, html.mobilelayout .topsearch {
  overflow: visible;
}
html.searchpage .topsearch {
  height: auto;
}
.topsearch .wh-autocomplete-values {
  opacity: 0;
  transition: opacity 0.2s;
}
html.show-topsearch .topsearch .wh-autocomplete-values, html.searchpage .topsearch .wh-autocomplete-values, html.mobilelayout .topsearch .wh-autocomplete-values {
  opacity: 1;
}
.topsearch > form {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  position: relative;
  height: 105px;
  padding-top: 30px;
  padding-left: 250px;
  font-size: 16px;
}
@media (max-width: 600px) {
  .topsearch > form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1170px) {
  .topsearch > form {
    padding-left: 250px;
  }
}
.topsearch > form > input, .topsearch > form > button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 45px;
  border: 0 none;
  font-size: 16px;
  border-radius: 0;
  font: inherit;
  color: inherit;
}
.topsearch > form input {
  width: 100%;
  padding: 12px 55px 12px 15px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0.75);
}
.topsearch > form button {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 44px;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
}
@media (max-width: 600px) {
  html.mobilelayout .topsearch > form {
    padding-left: 20px;
  }
}
html.mobilelayout .topsearch {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  padding-top: 4px;
  height: 50px;
  transition: height 0s;
  display: none;
  background-color: transparent;
  color: #fff;
  box-shadow: none;
  background-color: #5B4041;
}
html.mobilelayout .topsearch > form {
  height: 100%;
  padding-top: 0;
  margin-right: 30px;
  margin-left: 30px;
  width: auto;
  padding-right: 0;
  padding-left: 0;
}
html.mobilelayout .topsearch > form input {
  padding-left: 0;
  background-color: transparent;
}
html.mobilelayout .topsearch > form button {
  right: 0;
  top: 0;
}
@media (max-width: 600px) {
  html.mobilelayout .topsearch > form {
    padding-left: 20px;
    padding-right: 20px;
  }
  html.mobilelayout .topsearch > form button {
    right: 15px;
  }
}
html.mobilelayout.showmobilemenu .topsearch {
  z-index: 2; /* on top of mainnav */
  display: block;
}
html.mobilelayout.showmobilemenu .topsearch form {
  max-width: 100%;
}

.searchresults {
  margin-top: 80px;
}
.searchresults ul {
  list-style-type: none;
  padding: 30px 0 0;
  margin: 0;
  border-top: 1px solid #C5C5C5;
  margin-top: 40px;
}
.searchresults ul > li {
  padding-bottom: 30px;
}
.searchresults a {
  color: inherit;
  text-decoration: none;
  line-height: 170%;
  display: block;
  position: relative;
}
.searchresults a .title {
  font: 700 18px/120% inherit;
  padding-bottom: 5px;
}
.searchresults a .link {
  padding-top: 5px;
  text-decoration: underline;
}
.searchresults a > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchresults a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -30px;
  bottom: -15px;
  left: -30px;
  background-color: #F3F3F1;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border-radius: 3px;
}
.searchresults a:hover::before {
  opacity: 1;
}
@media (max-width: 600px) {
  .searchresults a::before {
    right: -20px;
    left: -20px;
  }
}