/* photointro */
.widget-introblock {
  display: flex;
  margin-top: -60px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 30px;
  position: relative; /* keep on top of next widget */
}
.widget-introblock--dark {
  background-color: #5B4041;
  color: #fff;
}
.widget-introblock--dark h1 {
  color: inherit;
}
.widget-introblock__image {
  max-width: calc(66.6666% - 20px);
  position: relative;
  flex: 1 1 100%;
  background-color: #5B4041;
}
.widget-introblock__image__wrapper {
  position: relative;
}
.widget-introblock__image__text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 13px;
  padding: 4px 15px;
  z-index: 1; /* on top of image */
}
.widget-introblock__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-introblock__text {
  display: flex;
  flex-basis: 100%;
  align-items: flex-start;
  padding: 30px;
  flex-flow: column;
  justify-content: center;
  background-color: #F5F3F1;
}
.widget-introblock__text > *:last-child {
  margin-bottom: 0;
}
.widget-introblock--dark .widget-introblock__text {
  background-color: inherit;
}
@media (max-width: 1000px) {
  .widget-introblock__image {
    max-width: 50%;
  }
}
@media (max-width: 900px) {
  .widget-introblock {
    display: block;
  }
  .widget-introblock__image {
    max-width: 100%;
  }
  .widget-introblock__text {
    padding-top: 20px;
  }
}
@media (max-width: 600px) {
  .widget-introblock {
    margin-bottom: 20px;
  }
  .widget-introblock__text {
    padding: 20px;
  }
}