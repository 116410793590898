html {
  background-color: #F5F3F1;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
html.mobilelayout.showmobilemenu body, html.hidescroll body {
  overflow: hidden;
}

header, footer {
  flex: none;
}

html.beforeload * {
  transition-duration: 0s !important;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding: 0;
  position: relative;
}

.pageimage + article {
  margin-top: 30px;
}

html.searchpage main {
  padding-top: 35px;
}

article {
  margin-top: 60px;
}

.foldertitle {
  font-family: "Alegreya Sans SC", sans-serif, serif, Arial;
}

article > * {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  article > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.centercontent,
article > .xwidecontent,
.fullwidth > .xwidecontent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
}
@media (max-width: 600px) {
  .centercontent,
  article > .xwidecontent,
  .fullwidth > .xwidecontent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

article > .fullwidth {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.wh-rtd__anchor {
  display: inline-block;
  position: absolute;
  transform: translateY(-102px);
}
html.mobilelayout .wh-rtd__anchor {
  transform: translateY(-42px);
}

@media (max-width: 700px) {
  article {
    margin-top: 20px;
  }
}
audio::-webkit-media-controls-panel {
  background-color: #ddd;
}

/*
audio::-webkit-media-controls-mute-button
{}
audio::-webkit-media-controls-play-button
{}
audio::-webkit-media-controls-timeline-container
{}
audio::-webkit-media-controls-current-time-display
{}
audio::-webkit-media-controls-time-remaining-display
{}
audio::-webkit-media-controls-timeline
{}
audio::-webkit-media-controls-volume-slider-container
{}
audio::-webkit-media-controls-volume-slider
{}
audio::-webkit-media-controls-seek-back-button
{}
audio::-webkit-media-controls-seek-forward-button
{}
audio::-webkit-media-controls-fullscreen-button
{}
audio::-webkit-media-controls-rewind-button
{}
audio::-webkit-media-controls-return-to-realtime-button
{}
audio::-webkit-media-controls-toggle-closed-captions-button
{}
*/
/* photogrid */
.photogrid {
  margin-top: 30px;
}
.photogrid ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -30px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.photogrid a {
  display: block;
  text-decoration: none;
}
.photogrid__item {
  flex: 1 1 50%;
  max-width: 570px;
  min-width: 180px;
  padding-bottom: 30px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  /* animation fx when scrolling into view */
  transform: translate3d(0, 100px, 0);
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.photogrid__item.animate {
  opacity: 1;
  transform: translateZ(0);
}
.photogrid__item--portrait {
  max-width: 25%;
}
.photogrid__item:hover .photogrid__item__thumb {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.photogrid__item__thumb:focus-within {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.photogrid__item--audio .photogrid__item__thumb {
  min-height: 30px;
  background-color: #ddd;
  color: #000;
}
.photogrid__item--audio .photogrid__item__thumb img {
  padding-bottom: 30px;
}
.photogrid__item--audio .photogrid__item__thumb::before {
  content: "";
  display: block;
  position: relative;
  height: 30px;
  border: 14px solid #ddd;
  border-left-width: 40px;
  background-color: #aaa;
}
.photogrid__item--audio .photogrid__item__thumb::after {
  font-size: 10px;
  line-height: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\f04b";
  font-weight: 700;
  position: absolute;
  bottom: 10px;
  left: 17px;
}
.photogrid__item--audio .photogrid__item__thumb.audioloaded::before, .photogrid__item--audio .photogrid__item__thumb.audioloaded::after {
  display: none;
}
.photogrid__item--audio .photogrid__item__thumb.audioloaded {
  cursor: default;
}
.photogrid__item--audio .photogrid__item__thumb audio {
  border-radius: 0;
  position: relative;
  vertical-align: bottom;
  background-color: #ddd;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  outline: none;
}
.photogrid__item--video .photogrid__item__thumb {
  padding-top: 56.25%; /* 16/9 */
  background-color: #333;
  color: #fff;
}
.photogrid__item--video .photogrid__item__thumb::after {
  font-size: 60px;
  line-height: 60px;
  font-family: "Font Awesome 5 Pro";
  content: "\f04b";
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  transition: opacity 0.2s;
}
.photogrid__item--video .photogrid__item__thumb:hover::after {
  opacity: 0.2;
}
.photogrid__item--video .photogrid__item__thumb.videoloaded::after {
  display: none;
}
.photogrid__item--video .photogrid__item__thumb video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
}
.photogrid__item__thumb {
  position: relative;
}
.photogrid__item__thumb img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.photogrid__item__thumb + div {
  padding-top: 10px;
}
.photogrid__item__title {
  font-family: "Alegreya Sans SC", sans-serif, serif, Arial;
  font-size: 18px;
  position: relative;
}
a .photogrid__item__title {
  padding-right: 15px;
}
.photogrid__item__arrow {
  position: absolute;
  top: 11px;
  right: -2px;
  font-size: 16px;
  transform: rotate(45deg);
}

/* contentlist */
.contentlist {
  list-style-type: none;
  margin: 30px 0 15px;
  padding: 0;
}
.contentlist a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: inherit;
  text-decoration: none;
  position: relative;
}
.contentlist a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -30px;
  bottom: -15px;
  left: -30px;
  background-color: #F3F3F1;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border-radius: 3px;
}
.contentlist a:hover::before {
  opacity: 1;
}
.contentlist__item + .contentlist__item {
  margin-top: 30px;
}
.contentlist__item__text > * {
  display: block;
  line-height: 28px;
}
.contentlist__item__text__title {
  font: bold 26px/140% inherit;
}
.contentlist__item__text__description {
  padding-top: 5px;
}
@media (max-width: 600px) {
  .contentlist__item a::before {
    right: -20px;
    left: -20px;
  }
  .contentlist__item__image {
    display: none;
  }
}