.widget-introblock + .widget-twocol--dark {
  margin-top: -75px;
  padding-top: 60px;
}

.widget-twocol--dark {
  background-color: #5B4041;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}
.widget-twocol--small .widget-twocol__columns {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  .widget-twocol--small .widget-twocol__columns {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-twocol__columns {
  display: flex;
}
.widget-twocol__columns__col {
  flex: 0 1 50%;
  max-width: 50%;
  padding-right: 15px;
}
.widget-twocol__columns__col > *:last-child {
  margin-bottom: 0;
}
.widget-twocol__columns__col + .widget-twocol__columns__col {
  padding-left: 15px;
  padding-right: 0px;
}
@media (max-width: 600px) {
  .widget-twocol__columns {
    display: block;
  }
  .widget-twocol__columns__col {
    max-width: 100%;
    padding-right: 0;
  }
  .widget-twocol__columns__col + .widget-twocol__columns__col {
    padding-top: 20px;
    padding-left: 0;
  }
}