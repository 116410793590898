footer {
  background-color: #5B4041;
  color: #fff;
  font-size: 13px;
  flex: none;
}
footer a {
  color: inherit;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
footer li {
  display: inline-block;
  padding-left: 15px;
}
footer .centercontent {
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html.mobilelayout footer .centercontent {
  flex-wrap: wrap;
}