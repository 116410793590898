/* Most of the basic video styling is loaded by shared/rtd/rtd.es, @mod-publisher/js/richcontent/all */
.wh-video {
  margin-bottom: 30px;
}
.wh-video__innerframe {
  background-color: #000;
}
.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent url(../../web/img/play.png) 50% 50% no-repeat;
  background-size: 15%;
  left: 0;
  top: 0;
  opacity: 0.75;
  transition: opacity 0.3s;
}
.wh-video__playbutton:hover {
  opacity: 0.9;
}
.wh-video__title {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 50%;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  font-size: 14px;
  padding: 15px 20px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}