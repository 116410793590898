body {
  padding-top: 78px;
}

header {
  color: inherit;
  height: 78px;
  z-index: 10;
  position: relative;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: "Alegreya Sans", sans-serif, serif, Arial;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  /* prevent occacional double click from selecting text */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
html.mobilelayout header {
  height: 40px;
}
header a {
  color: inherit;
  text-decoration: none;
}
header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header *[tabindex]:focus {
  outline: none;
}
header .mainnav {
  height: inherit;
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  position: relative;
  z-index: 2; /* keep on top of topsearch */
}
header .mainnav .mobilenav-footer {
  display: none;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #fff;
  font-size: 12px;
}
html.mobilelayout.showmobilemenu header .mainnav {
  display: block;
}
html.mobilelayout.showmobilemenu header .mainnav .mobilenav-footer {
  display: block;
}
html.mobilelayout.showmobilemenu header .mainnav .logo {
  display: none;
}
html.mobilelayout header .mainnav {
  display: none;
  position: fixed;
  top: 40px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
header .mainnav .searchtoggle__icon {
  transition: text-shadow 0.2s;
  cursor: pointer;
}
header .mainnav .searchtoggle__icon:hover {
  text-shadow: 0 0 1px #fff;
}
header .mainnav .togglechildren {
  position: absolute;
  right: 0;
  top: 7px;
  font-size: 16px;
  display: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
header .mainnav > div {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  header .mainnav > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header .mainnav > div > ul {
  white-space: nowrap;
}
header .mainnav > div > ul > li {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  height: 100%;
}
header .mainnav > div > ul > li > a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  padding: 5px 10px;
}
header .mainnav > div > ul > li:hover > a, header .mainnav > div > ul > li.active > a {
  text-shadow: 0 0 1px #5B4041;
}
header .mainnav > div > ul > li.active > a::after {
  content: "";
  display: block;
  border-bottom: 1px solid #5B4041;
}
header .mainnav > div > ul > li > .mainnav__children {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 245px;
  white-space: normal;
}
header .mainnav > div > ul > li > .mainnav__children .mainnav__children {
  display: none; /* only for mobile */
}
header .mainnav > div > ul > li > .mainnav__children ul {
  margin-top: 5px;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #5B4041;
  padding: 15px 0 20px;
}
header .mainnav > div > ul > li > .mainnav__children li {
  display: block;
}
header .mainnav > div > ul > li > .mainnav__children a {
  display: block;
  padding: 5px 20px;
  color: #fff;
}
html.mobilelayout header .mainnav > div > ul > li > .mainnav__children a {
  color: inherit;
}
header .mainnav > div > ul > li > .mainnav__children li.active > a,
header .mainnav > div > ul > li > .mainnav__children a:hover {
  text-decoration: underline;
}
header .mainnav > div > ul > li:hover > .mainnav__children, header .mainnav > div > ul > li > a:focus + .mainnav__children {
  display: block;
}
header .mainnav > div > ul > li.searchtoggle {
  font-size: 16px;
  margin-left: 35px;
  transition: opacity 0.2s;
}
html.show-topsearch header .mainnav > div > ul > li.searchtoggle {
  opacity: 0;
}
html.mobilelayout header .mainnav > div {
  display: block;
  font-size: 18px;
  line-height: 22px;
  height: auto;
  margin-top: 60px;
  font-size: 17px;
}
html.mobilelayout header .mainnav > div .searchtoggle {
  display: none;
}
html.mobilelayout header .mainnav > div li {
  position: relative;
}
html.mobilelayout header .mainnav > div .togglechildren--show, html.mobilelayout header .mainnav > div .showchildren > .togglechildren--hide,
html.mobilelayout header .mainnav > div .showchildren > .mainnav__children,
html.mobilelayout header .mainnav > div li:hover > a + .mainnav__children,
html.mobilelayout header .mainnav > div li:hover > .togglechildren--hide {
  display: block;
}
html.mobilelayout header .mainnav > div li:focus-within > a + .mainnav__children,
html.mobilelayout header .mainnav > div li:focus-within > .togglechildren--hide {
  display: block;
}
html.mobilelayout header .mainnav > div .showchildren > .togglechildren--show,
html.mobilelayout header .mainnav > div li:hover > .togglechildren--show {
  display: none;
}
html.mobilelayout header .mainnav > div li:focus-within > .togglechildren--show {
  display: none;
}
html.mobilelayout header .mainnav > div > ul {
  white-space: normal;
  padding-top: 15px;
  max-width: 100%;
  white-space: normal;
  height: auto;
}
html.mobilelayout header .mainnav > div > ul li {
  display: block;
}
html.mobilelayout header .mainnav > div > ul li > a {
  padding: 7px 0;
  border: 0 none;
}
html.mobilelayout header .mainnav > div > ul li .mainnav__children {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  width: 100%;
  font-size: 14px;
}
html.mobilelayout header .mainnav > div > ul li .mainnav__children a {
  padding: 0 25px 3px 0;
}
html.mobilelayout header .mainnav > div > ul li .mainnav__children a.active > a,
html.mobilelayout header .mainnav > div > ul li .mainnav__children a a:hover {
  background-color: transparent;
  text-decoration: underline;
}
html.mobilelayout header .mainnav > div > ul li .mainnav__children ul {
  padding: 0 0 0 10px;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
}
header .logo {
  display: inline-block;
  flex: none;
  position: relative;
  font-style: italic;
  font-size: 46px;
  line-height: 120%;
  font-weight: bold;
}
header .logo--mobile {
  padding: 5px 0 0 10px;
  display: none;
}
html.mobilelayout header .logo {
  font-size: 26px;
}
html.mobilelayout header .logo--mobile {
  display: block;
}
header .menutoggle {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  padding-right: 10px;
  text-align: center;
}
header .menutoggle .label {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  padding-right: 10px;
}
header .menutoggle .show {
  width: 15px;
  display: inline-block;
}
header .menutoggle .hide {
  width: 15px;
  display: none;
}
html.mobilelayout header .menutoggle {
  display: flex;
}
html.showmobilemenu header .menutoggle .hide {
  display: inline-block;
}
html.showmobilemenu header .menutoggle .show {
  display: none;
}