.widget-photorow {
  margin-top: 20px;
  padding: 0;
  max-width: 100vw;
  cursor: pointer;
  font-family: "Alegreya Sans SC", sans-serif, serif, Arial;
}
.widget-photorow a {
  color: inherit;
}
.widget-photorow__container {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 178px;
  background-color: #fff;
  position: relative;
  transition: transform 0.4s;
  transform-origin: 50% 50%;
}
.widget-photorow__container:hover {
  transform: scale(1.02);
}
.widget-photorow__container::after {
  content: attr(data-title);
  display: block;
  opacity: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  transition: opacity 0.3s;
  padding: 0 10px;
  line-height: 178px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}
.widget-photorow__container:hover::after {
  opacity: 1;
}
.widget-photorow__title {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 600px) {
  .widget-photorow__title {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-photorow__title a {
  text-decoration: none;
}
.widget-photorow__item {
  height: 100%;
  display: inline-block;
  border: 10px solid #fff;
}
.widget-photorow__item + .widget-photorow__item {
  border-left: 0 none;
}
.widget-photorow__item__imgwrapper {
  position: relative;
  outline: 1px solid #f6f6f6;
}
.widget-photorow__item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}