/*
Because this css is also used for rtd-preview, use plain css

brown: #5B4041 default
red:   #CB2C2F
blue:  #19375F
green: #00544B

*/

.widget-cta--center
{
  text-align: center;
}
.widget-cta--right
{
  text-align: right;
}

.widget-cta__button
{
  font: normal 16px/20px 'Alegreya Sans', sans-serif, serif, Arial;
  text-decoration: none;
  display: inline-block;
  color: inherit;
  position: relative;
  background-color: transparent;
  border-radius: 3px;
  text-align: left;
  height: 48px;
  padding: 15px 20px;
  min-width: 255px;
  max-width: 100%;
  transition: opacity 0.2s;
  cursor: pointer;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.widget-cta__button:hover
{
  opacity: 0.6;
}

.widget-cta__button__title
{
  display: block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}

.widget-cta__button__icon
{
  position: absolute;
  font-size: 22px;
  right: 20px;
  top: 50%;
  margin-top: -11px;
}
.widget-cta__button__icon + .widget-cta__button__title
{
  padding-right: 35px;
}

/* solid */
.widget-cta--solid a
{
  background-color: #5B4041;
  color: #fff;
}
.widget-cta--solid.widget-cta--red a
{
  background-color: #CB2C2F;
}
.widget-cta--solid.widget-cta--blue a
{
  background-color: #19375F;
}
.widget-cta--solid.widget-cta--green a
{
  background-color: #00544B;
}

/* outline */
.widget-cta--outline > a
{
  color: #5b4041;
  border: 1px solid #5b4041;
}
.widget-cta--outline.widget-cta--red a
{
  color: #CB2C2F;
  border-color: #CB2C2F;
}
.widget-cta--outline.widget-cta--blue a
{
  color: #19375F;
  border-color: #19375F;
}
.widget-cta--outline.widget-cta--green a
{
  color: #00544B;
  border-color: #00544B;
}

/* text */
.widget-cta--text > a
{
  color: #5b4041;
  min-width: 0;
  padding: 0;
  height: auto;
}
.widget-cta--text .widget-cta__button__icon
{
  right: auto;
  left: 0;
}
.widget-cta--text .widget-cta__button__icon + .widget-cta__button__title
{
  padding: 4px 0 0 15px;
}
