.widget-phototext {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}
@media (max-width: 600px) {
  .widget-phototext {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-phototext__title {
  padding: 30px 30px 10px 30px;
  color: inherit;
  max-width: 66.666%;
  font-family: "Alegreya", sans-serif, serif, Arial;
  font-size: 40px;
}
.widget-phototext p {
  line-height: 200%;
}
.widget-phototext a {
  text-decoration: none;
  font-style: italic;
  color: inherit;
}
.widget-phototext a .fal {
  font-size: 14px;
  padding-right: 10px;
}
.widget-phototext a:hover {
  text-decoration: underline;
}
.widget-phototext__container {
  background-color: #5B4041;
  width: calc(66.6666% - 20px);
}
.widget-phototext--photoleft {
  justify-content: flex-end;
}
.widget-phototext--photoleft .widget-phototext__title {
  margin-left: 33.3333%;
}
.widget-phototext--photoleft .widget-phototext__text {
  order: 2;
}
.widget-phototext__inner {
  display: flex;
}
.widget-phototext__text {
  flex: 1 1 100%;
  font-family: "Alegreya Sans", sans-serif, serif, Arial;
  font-weight: 300;
  padding: 30px;
}
h2 + .widget-phototext__inner .widget-phototext__text {
  padding-top: 0;
}
.widget-phototext__description > *:last-child {
  margin-bottom: 0;
}
.widget-phototext__link {
  margin-top: 20px;
}
.widget-phototext__photoholder {
  min-width: 33.3333%;
  position: relative;
}
.widget-phototext__photo {
  min-height: 100%;
  width: calc(250% + 30px);
  position: relative;
}
.widget-phototext__photo__wrapper {
  background-color: #ddd;
  position: relative;
  vertical-align: bottom;
  width: 100%;
}
.widget-phototext__photo__text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 13px;
  padding: 4px 15px;
}
.widget-phototext__photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-phototext--photoleft .widget-phototext__photo {
  margin-left: calc(-150% - 30px);
}
@media (max-width: 750px) {
  .widget-phototext {
    display: block;
  }
  .widget-phototext--photoleft .widget-phototext__title {
    margin-left: 0;
  }
  .widget-phototext__container {
    width: auto;
  }
  .widget-phototext__photo {
    width: 100%;
  }
  .widget-phototext--photoleft .widget-phototext__photo {
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .widget-phototext {
    padding-left: 0;
    padding-right: 0;
  }
  .widget-phototext__title {
    padding: 20px 20px 5px;
  }
  .widget-phototext__text {
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .widget-phototext__inner {
    display: block;
  }
  .widget-phototext__photo {
    display: none;
  }
}